
import { defineComponent, computed } from 'vue'
import HomeworkStatus from '@web/consts/HomeworkStatus'

export default defineComponent({
  props: {
    homework: {
      type: Object,
      default: () => ({})
    }
  },
  setup (props) {
    const isDone = computed(() => props.homework.status.status === HomeworkStatus.DONE)
    const theme = computed(() => isDone.value ? '-gray' : '')

    const link = computed(() => {
      return {
        name: 'homework',
        params: {
          homeworkId: props.homework.status.id,
          streamId: props.homework.status.streamId
        }
      }
    })

    return {
      isDone,
      theme,
      link
    }
  }
})
