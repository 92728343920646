import { computed } from 'vue'
import { useStore } from '@web/store'
import { useHomeworksBanners } from '@web/composition/useBanners'
const store = useStore()

function useHomeworks () {
  const user = computed(() => store.getters['user/user'])
  const username = computed(() => store.getters['user/username'])
  const menu = computed(() => store.getters['menu/general'])
  const homeworks = computed(() => Object.values(store.getters['homework/homeworksGrouped']))
  const streams = computed(() => store.getters['stream/streams'])
  const isLoadedStreams = computed(() => store.getters['stream/isLoadedAll'])
  const loadedStages = computed(() => store.getters['stage/loaded'])

  return {
    user,
    username,
    menu,
    homeworks,
    streams,
    isLoadedStreams,
    loadedStages,

    ...useHomeworksBanners()
  }
}

export default useHomeworks
