
import { defineComponent } from 'vue'
import AsPreloader from '@web/components/AsPreloader.vue'
import AsHomeworkItem from '@web/components/AsHomeworkItem.vue'
import AsEmpty from '@web/components/AsEmpty.vue'

export default defineComponent({
  components: {
    AsHomeworkItem,
    AsPreloader,
    AsEmpty
  },
  props: {
    homework: {
      type: Object,
      default: () => ({})
    }
  },
  setup () {
    function getHomeworks (data) {
      return data.map(stage => stage.homeworks).flat()
    }

    return {
      getHomeworks
    }
  }
})
