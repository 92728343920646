import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "asHomeworkItem_col" }
const _hoisted_2 = { class: "asHomeworkItem_title" }
const _hoisted_3 = { class: "asHomeworkItem_note" }
const _hoisted_4 = { class: "asHomeworkItem_button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.link,
    class: _normalizeClass(["asHomeworkItem", _ctx.theme])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["asHomeworkItem_status", { '-done': _ctx.isDone }])
      }, null, 2),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.homework.homework.title), 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.homework.homework.note), 1)
      ]),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t(`homeworkStatuses['${_ctx.homework.status.status}']`)), 1)
    ]),
    _: 1
  }, 8, ["to", "class"]))
}