import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "asHomeworkPreview" }
const _hoisted_2 = { class: "asHomeworkPreview_caption" }
const _hoisted_3 = { class: "asHomeworkPreview_items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_preloader = _resolveComponent("as-preloader")!
  const _component_as_empty = _resolveComponent("as-empty")!
  const _component_as_homework_item = _resolveComponent("as-homework-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.homework.caption), 1),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.homework.statusLoad === 'loading')
        ? (_openBlock(), _createBlock(_component_as_preloader, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.homework.statusLoad === 'error')
        ? (_openBlock(), _createBlock(_component_as_empty, { key: 1 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('homework')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.homework.statusLoad === 'done')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getHomeworks(_ctx.homework.data), (item, id) => {
              return (_openBlock(), _createBlock(_component_as_homework_item, {
                homework: item,
                key: id
              }, null, 8, ["homework"]))
            }), 128)),
            (_ctx.getHomeworks(_ctx.homework.data).length === 0)
              ? (_openBlock(), _createBlock(_component_as_empty, { key: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('homeworkNotFound')), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ])
  ]))
}