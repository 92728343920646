import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList } from "vue"

const _hoisted_1 = { class: "lUser" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_header_mobile = _resolveComponent("as-header-mobile")!
  const _component_as_homework_preview = _resolveComponent("as-homework-preview")!
  const _component_as_homeworks = _resolveComponent("as-homeworks")!
  const _component_as_empty = _resolveComponent("as-empty")!
  const _component_as_preloader = _resolveComponent("as-preloader")!
  const _component_as_banners_catalog = _resolveComponent("as-banners-catalog")!
  const _component_mq_responsive = _resolveComponent("mq-responsive")!
  const _component_as_breadcrumb = _resolveComponent("as-breadcrumb")!
  const _component_as_header_main = _resolveComponent("as-header-main")!
  const _component_as_menu = _resolveComponent("as-menu")!
  const _component_as_banner = _resolveComponent("as-banner")!
  const _component_as_banners_top = _resolveComponent("as-banners-top")!
  const _component_as_title = _resolveComponent("as-title")!
  const _component_as_row = _resolveComponent("as-row")!
  const _component_as_spacer = _resolveComponent("as-spacer")!
  const _component_as_footer = _resolveComponent("as-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_mq_responsive, { target: "mobile-tablet" }, {
      default: _withCtx(() => [
        _createVNode(_component_as_header_mobile, {
          title: _ctx.$t('homeworks'),
          prevPage: _ctx.prevPage,
          back: "",
          "onMenu:toggle": _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpenMenu = $event))
        }, null, 8, ["title", "prevPage"]),
        (_ctx.loaded)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _withDirectives(_createVNode(_component_as_homeworks, { homeworks: _ctx.homeworks }, {
                default: _withCtx((props) => [
                  _createVNode(_component_as_homework_preview, {
                    homework: props.homework
                  }, null, 8, ["homework"])
                ]),
                _: 1
              }, 8, ["homeworks"]), [
                [_vShow, !_ctx.isOpenMenu]
              ]),
              (_ctx.homeworks.length === 0)
                ? _withDirectives((_openBlock(), _createBlock(_component_as_empty, {
                    key: 0,
                    class: "-inner"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('emptyHomeworks')), 1)
                    ]),
                    _: 1
                  }, 512)), [
                    [_vShow, !_ctx.isOpenMenu]
                  ])
                : _createCommentVNode("", true)
            ], 64))
          : _withDirectives((_openBlock(), _createBlock(_component_as_preloader, { key: 1 }, null, 512)), [
              [_vShow, !_ctx.isOpenMenu]
            ]),
        _withDirectives(_createVNode(_component_as_banners_catalog, {
          banners: _ctx.bannersCatalog,
          position: _ctx.bannersPosition
        }, null, 8, ["banners", "position"]), [
          [_vShow, !_ctx.isOpenMenu]
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_mq_responsive, {
      class: "lUser bg-blue",
      target: "desktop"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_as_header_main, null, {
          breadcrumb: _withCtx(() => [
            _createVNode(_component_as_breadcrumb, { title: _ctx.pageTitle }, null, 8, ["title"])
          ]),
          _: 1
        }),
        _createVNode(_component_as_row, null, {
          menu: _withCtx(() => [
            _createVNode(_component_as_menu, {
              menu: _ctx.menu,
              status: _ctx.status,
              type: "sidebar"
            }, null, 8, ["menu", "status"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bannersSidebar, (banner, i) => {
              return (_openBlock(), _createBlock(_component_as_banner, {
                key: `bannerSidebar${i}`,
                banner: banner,
                platform: _ctx.bannersPlatforms.desktop,
                position: _ctx.bannersPosition,
                placement: _ctx.bannersPlacements.sidebar
              }, null, 8, ["banner", "platform", "position", "placement"]))
            }), 128))
          ]),
          content: _withCtx(() => [
            _createVNode(_component_as_banners_top, {
              banners: _ctx.bannersTop,
              position: _ctx.bannersPosition,
              placement: _ctx.bannersPlacements.top,
              platform: _ctx.bannersPlatforms.desktop
            }, null, 8, ["banners", "position", "placement", "platform"]),
            _createVNode(_component_as_row, { class: "-inner" }, {
              content: _withCtx(() => [
                _createVNode(_component_as_title, { class: "-withoutShadow" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('homeworks')), 1)
                  ]),
                  _: 1
                }),
                (_ctx.loaded)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createVNode(_component_as_homeworks, { homeworks: _ctx.homeworks }, {
                        default: _withCtx((props) => [
                          _createVNode(_component_as_homework_preview, {
                            homework: props.homework
                          }, null, 8, ["homework"])
                        ]),
                        _: 1
                      }, 8, ["homeworks"]),
                      (Object.values(_ctx.homeworks).length === 0)
                        ? (_openBlock(), _createBlock(_component_as_empty, {
                            key: 0,
                            class: "-inner"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('emptyHomeworks')), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ], 64))
                  : (_openBlock(), _createBlock(_component_as_preloader, { key: 1 }))
              ]),
              _: 1
            }),
            (_ctx.loaded)
              ? (_openBlock(), _createBlock(_component_as_banners_top, {
                  key: 0,
                  banners: _ctx.bannersBottom,
                  position: _ctx.bannersPosition,
                  placement: _ctx.bannersPlacements.bottom,
                  platform: _ctx.bannersPlatforms.desktop
                }, null, 8, ["banners", "position", "placement", "platform"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_as_spacer),
        _createVNode(_component_as_footer)
      ]),
      _: 1
    })
  ]))
}