
import { defineComponent, ref, onMounted } from 'vue'
import { MqResponsive } from 'vue3-mq'

import AsHomeworks from '@web/components/AsHomeworks.vue'
import AsHomeworkPreview from '@web/components/AsHomeworkPreview.vue'
import AsEmpty from '@web/components/AsEmpty.vue'
import AsMenu from '@web/components/AsMenu.vue'
import AsTitle from '@web/components/AsTitle.vue'
import AsRow from '@web/components/AsRow.vue'
import AsHeaderMain from '@web/components/headers/AsHeaderMain.vue'
import AsFooter from '@web/components/AsFooter.vue'
import AsSpacer from '@web/components/AsSpacer.vue'
import AsBreadcrumb from '@web/components/AsBreadcrumb.vue'
import AsPreloader from '@web/components/AsPreloader.vue'
import AsBannersTop from '@web/components/AsBannersTop.vue'
import AsBanner from '@web/components/AsBanner.vue'
import AsBannersCatalog from '@web/components/AsBannersCatalog.vue'

import i18n from '@web/plugins/i18n'
import Logger from '@web/common/Logger'
import Analytics from '@web/services/Analytics/Analytics'
import Swal from 'sweetalert2'
import { performanceEnd } from '@web/common/Utils'

import useHomeworks from '@web/composition/useHomeworks'
import { useStore } from '@web/store'
import { HomeworkGettersTree } from '@web/store/types/modules/homework'
import sentry from '@web/common/sentry'
import FirebasePerformance from '@web/services/firebasePerformance'
import { useRoute } from 'vue-router'
import { useMeta } from '@web/common/vueMeta'
import AsHeaderMobile from '@web/components/headers/AsHeaderMobile.vue'
import STREAM_STATUS from '@web/consts/StreamStatus'
import PAGE_NAME from '@web/consts/PAGE_NAME'

export default defineComponent({
  components: {
    AsBanner,
    AsBannersTop,
    AsBannersCatalog,
    MqResponsive,
    AsHeaderMobile,
    AsHomeworks,
    AsHomeworkPreview,
    AsEmpty,
    AsRow,
    AsMenu,
    AsTitle,
    AsHeaderMain,
    AsFooter,
    AsSpacer,
    AsBreadcrumb,
    AsPreloader
  },
  setup () {
    const store = useStore()
    const route = useRoute()

    const prevPage = { name: PAGE_NAME.HOME }

    const {
      user,
      username,
      menu,

      bannersTop,
      bannersBottom,
      bannersSidebar,
      bannersCatalog,
      bannersPlatforms,
      bannersPlacements,
      bannersPosition
    } = useHomeworks()

    const loaded = ref(false)
    const status = ref('')
    const isOpenMenu = ref(false)
    const pageTitle = i18n.global.t('homeworks')
    const title = i18n.global.t('myHomework')
    const toolbarButton = {
      name: i18n.global.t('openCatalogCourses'),
      url: '/course',
      classes: ''
    }

    useMeta({
      title: pageTitle
    })

    function updateStatus (status) {
      status.value = status === '-open' ? '-close' : '-open'
    }
    function showErrorAlert () {
      Swal.fire({
        icon: 'error',
        title: i18n.global.t('error.error'),
        text: i18n.global.t('error.homework')
      })
    }
    const homeworks = ref<Record<number, {
      statusLoad: string;
      caption: string;
      data?: ReturnType<HomeworkGettersTree['getHomeworkAvailableGroupedByStagesByStreamId']>;
    }>>({})
    function fetchData () {
      return store.dispatch('stream/fetchUserCourseStreamsCached')
        .then(streams => {
          (async () => {
            const filteredStreams = streams
              .filter(stream => stream.status !== STREAM_STATUS.ENDED &&
                stream.status !== STREAM_STATUS.STOPPED &&
                stream.status !== STREAM_STATUS.NOT_STARTED &&
                stream.status !== STREAM_STATUS.FREEZE
              )

            filteredStreams
              .forEach(({ id, courseId }) => {
                homeworks.value = {
                  ...homeworks.value,
                  [id]: {
                    caption: store.getters['course/getCourseById'](courseId)?.title || `${i18n.global.t('course')} ${courseId}`,
                    statusLoad: 'loading'
                  }
                }
              })
            for (const { id, courseId } of filteredStreams) {
              if (route.name !== 'homeworks') {
                break
              }
              try {
                const promise = new Promise<void>(resolve => setTimeout(async ({ id, courseId }) => {
                  await store.dispatch('stage/fetchStagesCached', { courseId })
                  await store.dispatch('stream/fetchStatuses', {
                    id,
                    expand: 'days,homeworks,stages'
                  })

                  homeworks.value[id] = {
                    ...homeworks.value[id],
                    statusLoad: 'done',
                    data: store.getters['homework/getHomeworkAvailableGroupedByStagesByStreamId'](id)
                  }
                  resolve()
                }, 0, { id, courseId }))
                await promise
              } catch (e) {
                homeworks.value[id].statusLoad = 'error'
                sentry.captureException(e)
                Logger.error(e)
              }
            }
          })()
        })
    }
    function catchErrorFetchData (e) {
      if (route.name === 'homeworks') {
        sentry.captureException(e)
        Logger.error(e)
        showErrorAlert()
      }
    }
    async function init () {
      let homeworksLoading = performance.now()
      const startLoading = Date.now()
      fetchData()
        .catch(catchErrorFetchData)
        .finally(() => {
          homeworksLoading = performanceEnd(homeworksLoading)
          Logger.info(`Homeworks loaded: ${homeworksLoading} ms`)
          Analytics.send({
            category: 'ux.performance',
            action: 'load_homeworks',
            label: homeworksLoading.toString()
          })
          loaded.value = true

          FirebasePerformance.record({
            traceName: 'load_homeworks',
            startTime: startLoading
          })
        })
    }

    onMounted(() => {
      init()
    })

    return {
      prevPage,
      loaded,
      status,
      isOpenMenu,
      pageTitle,
      title,
      toolbarButton,

      user,
      username,
      menu,
      homeworks,

      updateStatus,

      bannersTop,
      bannersBottom,
      bannersSidebar,
      bannersCatalog,
      bannersPlatforms,
      bannersPlacements,
      bannersPosition
    }
  }
})
